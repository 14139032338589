/* Header.css */
.header {
  background: #0096c8;
  padding: 1rem 5rem;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.header-brand {
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
}

.salir button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

#salirbtn {
  cursor: pointer;
}

.header a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s;
}

.header a:hover {
  color: #53baea;
}

.container-user {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.profile-img {
  margin-right: 8px; 
}

.profile-name {
  color: white;
  font-weight: bold;
  margin: 0;
  padding-top: 2px; 
}
