/* FormContainer.css */
#formulario-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.card {
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 2rem;
}

.form-label {
  width: 8rem;
  font-weight: bold;
}

.text-center-col {
  background-color: #176B87;
  color: aliceblue;
  border-radius: 1rem;
  overflow: hidden;
}

/* Estilo del contenedor de la imagen y el texto */
.container-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

/* Estilo de la imagen */
.container-img img {
  max-width: 100%;
  height: auto;
}

/* Estilos para los párrafos */
.parrafo-1 {
  font-size: 1.3rem;
  color: white;
  font-style: italic;
}

.parrafo-2 {
  font-size: 1.3rem;
  color: white;
  font-style: italic;
}

.parrafo-3 {
  font-size: 1.3rem;
  color: white;
  font-style: italic;
}
